import { Component } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Program } from '@xpo-ltl/sdk-offbillincentive';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AppRoutes } from '../../../enums/app-routes.enum';
import { Programs } from '../../../enums/agreement/program/programs.enum';

export interface GridProgramsCellRendererParams extends ICellRendererParams {
  programs: Program[];
  agreementId: string;
}

interface LinkableProgram {
  name: string;
  tooltip?: string;
  url?: UrlTree;
}
@Component({
  selector: 'app-obi-incentive-programs-cell-renderer',
  templateUrl: './grid-programs-cell-renderer.component.html',
  styleUrls: ['./grid-programs-cell-renderer.component.scss'],
})
export class GridProgramsCellRendererComponent implements ICellRendererAngularComp {
  programs: LinkableProgram[];
  agreementId: string;

  constructor(private router: Router) {}

  agInit(params: GridProgramsCellRendererParams) {
    if (params.programs) this.programs = this.renderPrograms(params.programs, params.agreementId);
  }

  renderPrograms(programs: Program[], agreementId?): LinkableProgram[] {
    return programs.map((value: any) => {
      const programId = value.obiProgramId;
      return {
        name: value.programTypeCd,
        tooltip:
          value.programTypeCd === Programs.vip || value.programTypeCd === Programs.eom
            ? `O/B PPD: ${value.outboundPrepaidCd === 'Y' ? value.outboundPrepaidRate : 'x'}\nO/B COL: ${
                value.outboundCollectCd === 'Y' ? value.outboundCollectRate : 'x'
              }\nI/B PPD: ${value.inboundPrepaidCd === 'Y' ? value.inboundPrepaidRate : 'x'}\nI/B COL: ${
                value.inboundCollectCd === 'Y' ? value.inboundCollectRate : 'x'
              }\n 3rd Party: ${value.thirdPartyCd === 'Y' ? value.thirdPartyRate : 'x'}\nMax Days to Pay: ${
                value.maxDaysToPay
              }`
            : `PPD: ${value.prepaidInd ? value.prepaidRate : 'x'}\nCOL: ${value.collectInd ? value.collectRate : 'x'}`,
        url: this.getProgramUrl(agreementId, programId),
      };
    });
  }

  getProgramUrl(agreementId?, programId?) {
    return !!agreementId && !!programId
      ? this.router.createUrlTree([
          AppRoutes.OBI_AGREEMENT,
          AppRoutes.PROGRAM_DETAILS,
          agreementId,
          programId,
          AppRoutes.PROGRAM_HDR,
        ])
      : undefined;
  }
  getTooltip(program): string {
    console.log('getTooltip', program);
    return 'test';
  }

  redirect(programUrl) {
    window.open(programUrl, '_blank');
  }

  refresh(): boolean {
    return false;
  }
}
