<mat-chip-list>
  <mat-chip *ngFor="let program of programs" xpoTag matTooltip="{{program.tooltip}}" matTooltipPosition="right">
    <ng-container *ngIf="program.url; else noLinkProgram">
      <a class="incentive-id" (click)="redirect(program.url)" routerLinkActive="active">{{ program.name }}</a>
    </ng-container>
    <ng-template #noLinkProgram>
      {{ program.name }}
    </ng-template>
  </mat-chip>
</mat-chip-list>
